import React from 'react'
import Container from 'react-bootstrap/Container'

const SectionContainer = ({ children, className, fluid = false }) => (
  <Container className={' ' + className} fluid={fluid}>
    {children}
  </Container>
)

export default SectionContainer
