import React from 'react'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import SectionImpressum from '../components/SectionImpressum'
import Seo from '../components/seo'

import '../assets/scss/styles.scss'

function ImpressumPage() {
  return (
    <Layout>
      <Seo title="Helena's Kinderbuchparty - Impressum" />
      <Navbar logoVisible={false} />
      <SectionImpressum />
    </Layout>
  )
}

export default ImpressumPage
